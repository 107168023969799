import { type Duration, type Locale, format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { de, enGB, fr } from 'date-fns/locale';

const locales: Record<string, Locale> = {
    de,
    fr,
    en: enGB,
};

/**
 * Creates a date object in the Zurich timezone.
 * @param args The arguments to pass to the Date constructor.
 * @returns The date in the Zurich timezone.
 */
export function zurichDate(...args: (string | number | Date | undefined | null)[]): Date {
    // @ts-expect-error Date constructor is stupid
    return toZonedTime(new Date(...args), 'Europe/Zurich');
}

/**
 * Formats the date as 'yyyy-MM-dd\'T\'HH:mm'.
 * @param date The date to format.
 * @returns The formatted date.
 */
export function formatDateTimeISO(date?: Date): string {
    return formatDate(date, 'yyyy-MM-dd\'T\'HH:mm');
}

/**
 * Formats a given date localized to "dd. MMM" (in DE) (e.g. 01. Jan).
 *
 * @param date - The date to format.
 * @returns The formatted date.
 */
export function formatMonthMedium(date?: Date): string {
    const format = useNuxtApp().$i18n.t('core.date.formatMonthMedium');
    return formatDate(date, format);
}

/**
 * Formats a given date localized to "dd. MMM yyyy" (in DE) (e.g. 01. Jan 2022).
 *
 * @param date - The date to format.
 * @returns The formatted date.
 */
export function formatMonthMediumYear(date?: Date): string {
    const format = useNuxtApp().$i18n.t('core.date.formatMonthMediumYear');
    return formatDate(date, format);
}

/**
 * Formats the date using the specified format pattern.
 * @param date The date to format.
 * @param f The format pattern.
 * @returns The formatted date.
 */
export function formatDate(date: Date | undefined, f: string): string {
    if (!date) {
        return '';
    }
    try {
        return format(date, f, { locale: locales[useNuxtApp().$i18n.locale.value] });
    }
    catch (e) {
        return '';
    }
}

/**
 * Parses ISO duration
 * @param iso8601Duration
 * @returns The parsed duration in years, months, weeks, days, hours, minutes and seconds.
 */
export function parseISODuration(iso8601Duration: string): Duration {
    const iso8601DurationRegex = /(-)?P(?:([.,\d]+)Y)?(?:([.,\d]+)M)?(?:([.,\d]+)W)?(?:([.,\d]+)D)?T(?:([.,\d]+)H)?(?:([.,\d]+)M)?(?:([.,\d]+)S)?/;

    const matches = iso8601Duration.match(iso8601DurationRegex);

    return {
        years: matches?.[2] ? Number.parseInt(matches[2]) : 0,
        months: matches?.[3] ? Number.parseInt(matches[3]) : 0,
        weeks: matches?.[4] ? Number.parseInt(matches[4]) : 0,
        days: matches?.[5] ? Number.parseInt(matches[5]) : 0,
        hours: matches?.[6] ? Number.parseInt(matches[6]) : 0,
        minutes: matches?.[7] ? Number.parseInt(matches[7]) : 0,
        seconds: matches?.[8] ? Number.parseInt(matches[8]) : 0,
    };
}
